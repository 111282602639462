<template>
    <div
            v-if="show"
            class="transaction-search"
            :class="navBarShow ? 'transaction-search-no-wechat' : ''"
    >
        <van-tabs v-model="menuTab" color="#5d74f2" sticky>
            <van-tab :title="$t('基本信息')" name="info">
                <van-cell-group :title="$t('基本信息')">
                    <van-cell :value="company.company_en" />
                    <van-cell :title="$t('税号')" :value="company.company_no" />
                    <van-cell :title="$t('企业性质')" :value="company.industry?company.industry.tagStr:''" />
                    <van-cell :title="$t('企业行业')" :value="company.tags?company.tags.tagStr:''" />
                    <van-cell :title="$t('成立日期')" :value="company.found_date" />
                    <van-cell :title="$t('注册资本')" :value="company.capital" />
                    <van-cell v-if="company.web?true:false" :title="$t('公司网站')" :value="company.web" />
                    <van-cell v-if="company.address?true:false" icon="location-o" :value="company.address"/>
                    <van-cell v-if="company.country?true:false" :title="$t('地区')" :value="company.country+'/'+company.province+'/'+company.city" />
                    <van-cell :title="$t('是否在园区')" :value="company.in_park" />
                </van-cell-group>
                <van-cell-group :title="$t('补充信息')">
                    <van-cell :title="$t('公司证书')" :value="company.cert_file" />
                    <van-cell :title="$t('其他说明')" :value="company.others" />
                </van-cell-group>
            </van-tab>

            <van-tab :title="$t('企业产品')" name="goods">
                <van-list
                        v-model="loading"
                        immediate-check
                        :finished="finished"
                        :loading-text="$t('加载中')"
                        :finished-text="$t('没有更多了')"
                        @load="onLoad"
                >
                    <van-cell-group v-for="item in goodsList" :key="item.id">
                        <van-cell :title="item.ename" :value="item.cas_no"/>
                    </van-cell-group>
                </van-list>

            </van-tab>
            <van-tab :title="$t('联系人')" name="contact">
                <van-list
                        v-model="loading"
                        immediate-check
                        :finished="finished"
                        :loading-text="$t('加载中')"
                        :finished-text="$t('没有更多了')"
                        @load="onLoad"
                >
                    <van-cell-group v-for="contact in contactList" :key="contact.id">
                        <van-cell :title="contact.name" :value="contact.dept+'/'+contact.position"/>
                        <van-cell :title="Tel" icon="phone-o" :value="contact.tel"/>
                        <van-cell :title="Email" icon="envelop-o" :value="contact.email"/>
                    </van-cell-group>
                </van-list>
            </van-tab>
        </van-tabs>
        <van-overlay :show="loadFlag">
            <div class="wrapper" @click.stop>
                <van-loading type="spinner" size="34px" color="#FFFFFF" />
            </div>
        </van-overlay>
    </div>
</template>

<script>
    import utils from "../utils/utils";

    export default {
        name: "ShowCustomer",
        data() {
            return {
                loadFlag:true,
                loading: false,
                finished: false,
                menuTab:"info",
                cus:"",
                cus_id:0,
                company: {},
                goodsList: [],
                contactList:[],
                navBarShow: false, // 是否有顶部导航栏
                show: false, // 搜索框显示
            };
        },
        props: {
            item: {
                // 传入数据
                type: Object,
                default: () => {
                },
            },
        },
        watch:{
            cus(val) {
                console.log('dasda');
                this.getCompany(val);
            },
        },
        mounted() {
            if (!utils.isWechat()) {
                this.navBarShow = true;
            }
        },
        methods: {
            toggleShow() {
                // 询盘框显示隐藏
                this.show = !this.show;
            },
            onLoad(){
                setTimeout(() => {
                    // 加载状态结束
                    this.loading = false;
                    this.finished = true;
                }, 1000);
            },
            getCompany(val) {
                let that = this;
                // 发布询盘
                if(val!=""&&val!=undefined){
                    that.loadFlag = true;
                    this.$http
                        .post("/v1/cusDetail", {
                            cus: this.cus,
                            cus_id:this.cus_id,
                        })
                        .then((res) => {
                            that.loadFlag = false;
                            if (res&&res.code == 200) {
                                //传递参数给父级组件
                                that.$emit('consumeEve', res.data.vo);
                                that.company = res.data.vo;
                                that.goodsList = res.data.goods_list;
                                that.contactList = res.data.contacts_list;
                            }else{
                                that.cus = "";
                                that.cus_id = 0;
                                that.toggleShow();
                            }
                        });
                }
            },

        },
    };
</script>

<style scoped>
    .transaction-search {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: white;
        padding-bottom: 5vw;
        overflow: auto;
    }
    .transaction-search-no-wechat {
        top: 46px !important;
        height: calc(100vh - 46px);
    }
    .service {
    }
    .service-body{
        padding: 12px 16px;
        border-radius: 5px;
        box-shadow: 0 0 15px 0 #ccc;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: fit-content;
        height: fit-content;
    }
    .service-qrcode{
        width: 240px;
        height: 240px;
    }
    .service-list{
        /* display: flex; */
        /* align-items: center; */
        border-bottom: 1px solid #f5f5f5;
        padding: 8px 0;
    }
    .service-list-title{
        color: #333;
    }
    .service-list-brief{
        margin-top: 5px;
        width: 240px;
        word-wrap:break-word;
        white-space: pre-wrap;
        color: #888;
    }
    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
</style>